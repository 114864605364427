import React from 'react'
import Layout from '../components/layout'
import Portrait from '../images/portrait.jpg'

const AboutPage = ({ data }) => (
  <Layout>
    <div className="who">
      <p className="css-1uembe2-Paragraph">
        <img src={Portrait} alt="Andrea Tangredi"/>
      </p>

      <p className="css-1uembe2-Paragraph">
        Professionally, I'm Co-Founder @ <a href="https://www.indigo.ai">Indigo.ai</a>, a company that works in Conversational Artificial Intelligence. Fascination with technology is what I grew up with and what shaped me, then I started to explore other fields such as philosophy and spirituality. When I'm not thinking about technology I'm usually dedicating my time to meditative practices, writing and generally trying to find valuable ways to expand my consciousness and to create meaning in my life.
      </p>
    </div>
  </Layout>
)

export default AboutPage
